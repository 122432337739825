@font-face {
  font-family: 'MetaOT';
  src: local('MetaOT'), url('./assets/fonts/MetaOT.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'MetaOTBold';

  src: local('MetaOT-Bold'), url('./assets/fonts/MetaOT-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Light.woff2') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Regular.woff2') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Medium.woff2') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Bold.woff2') format('truetype');
  font-weight: 1000;
}

@font-face {
  font-family: 'AvedaMonoBold';

  src: local('AvedaMono-Bold'), url('./assets/fonts/avedaMono/AvedaMono-Bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'AvedaMonoRegular';
  src: local('AvedaMono-Regular'),
    url('./assets/fonts/avedaMono/AvedaMono-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'AvedaSansBold';
  src: local('AvedaSans-Bold'), url('./assets/fonts/avedaSans/AvedaSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'AvedaSansBoldItalic';
  src: local('AvedaSans-BoldItalic'),
    url('./assets/fonts/avedaSans/AvedaSans-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'AvedaSansLight';
  src: local('AvedaSans-Light'),
    url('./assets/fonts/avedaSans/AvedaSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'AvedaSansLightItalic';
  src: local('AvedaSans-LightItalic'),
    url('./assets/fonts/avedaSans/AvedaSans-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'AvedaSansRegular';
  src: local('AvedaSans-Regular'),
    url('./assets/fonts/avedaSans/AvedaSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'AvedaSansRegularItalic';
  src: local('AvedaSans-RegularItalic'),
    url('./assets/fonts/avedaSans/AvedaSans-RegularItalic.woff2') format('woff2');
}

* {
  font-family: 'AvedaSansRegular';
  color: #000000;
}

b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'AvedaSansBold';
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#onetrust-banner-sdk.otFloatingRoundedIcon {
  z-index: 110 !important;
}

#onetrust-banner-sdk.otFloatingRoundedIcon.default {
  right: 5em;
  margin: auto;
}

body > div {
  padding-right: 0 !important;
}

iframe {
  z-index: 101 !important;
}
